import {
    Row,
    Col,
} from "reactstrap";
import React from "react";
import { Box } from '@mui/material';
import { Tab } from '@mui/material';
import { TabContext } from '@mui/lab';
import { TabList } from '@mui/lab';
import { TabPanel } from '@mui/lab';
import { Form } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import * as yup from 'yup';
import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import bgProfile from "../images/profile-placeholder.png";
import DragDropSelect from "../common/DragDropSelect";

const UpdateTool = () => {
    const [value, setValue] = useState("1");
    const [library, setLibrary] = useState("");
    const [model, setModel] = useState("");
    const [showAdditionalTabs, setShowAdditionalTabs] = useState(true);
    const [toolExists, setToolExists] = useState(false);

    const [submitBtn, setSubmitBtn] = useState({
        text: "Update",
        disabled: false
    });

    const [formData, setFormData] = useState({
        label: "",
        key: "",
        // value: "",
        limit: "",
        prompt: "",
        position: "",
        isActive: true,
        isSelectOccasion: "yes",
        selectOccasionList: [],
        selectedGender: [],
        createFor: [],
        createStyle: []
    });

    const selectGenders = [
        {
            value: 'male',
            label: 'Male',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW4GxOl8Aljz3MFxFHDGg_Vfaz-EjS85Zjud34r4N8caZQd3oWXx0zrBEuwSMoEw8O1nE&usqp=CAU'
        },
        {
            value: 'female',
            label: 'Female',
            image: 'https://img.freepik.com/premium-photo/woman-with-freckles-her-face_1197721-99165.jpg'
        },
        {
            value: 'neutral',
            label: 'Neutral',
            image: 'https://scandasia.com/wp-content/uploads/2023/06/gender-gap-735x441.jpeg'
        }
    ];

    const createFors = [
        {
            value: 'kids',
            label: 'Kids',
            image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnhg2JTdw9J_XRyE7zKFX8Nqcnv9wshmfF4Q&s'
        },
        {
            value: 'mother',
            label: 'Mother',
            image: 'https://thumbs.dreamstime.com/z/mother-child-indian-motifs-painting-s-day-generated-ai-gentle-cozy-picture-baby-mom-hair-decorated-cape-315256276.jpg'
        },
        {
            value: 'father',
            label: 'Father',
            image: 'https://media.istockphoto.com/id/1447126543/photo/father-bonding-kiss-and-boy-child-hug-happy-in-nature-with-quality-time-together-outdoor.jpg?s=612x612&w=0&k=20&c=iujHdzYFrmfLgVLKcAzu4y1-awTR2xRo5m-rD9w35wU='
        },
        {
            key: 'brother',
            label: 'Brother',
            image: 'https://www.shutterstock.com/image-photo/two-happy-boys-brothers-who-600nw-1738136738.jpg'
        },
        {
            value: 'sister',
            label: 'Sister',
            image: 'https://www.shutterstock.com/shutterstock/photos/1913542885/display_1500/stock-vector-two-girls-friends-sisters-hugging-with-their-hair-woven-in-flowers-1913542885.jpg'
        },
        {
            value: 'friend',
            label: 'Friend',
            image: 'https://cdn.pixabay.com/photo/2024/03/29/13/56/ai-generated-8662940_1280.png'
        },
        {
            value: 'girl',
            label: 'Girl',
            image: 'https://admin-varnz.webgarh.net/images/tool/girl.jpg'
        },
        {
            value: 'boy',
            label: 'Boy',
            image: 'https://admin-varnz.webgarh.net/images/tool/boy.jpg'
        }
    ];


    const createStyles = [
        {
            value: 'cinematic',
            label: 'Cinematic',
            image: 'https://admin-varnz.webgarh.net/images/tool/Cinematic.png'
        },
        {
            value: 'sketch',
            label: 'Sketch',
            image: 'https://admin-varnz.webgarh.net/images/tool/Sketch.png'
        },
        {
            value: 'vintage',
            label: 'Vintage',
            image: 'https://admin-varnz.webgarh.net/images/tool/Vintage.png'
        },
        {
            value: 'pixel',
            label: 'Pixel',
            image: 'https://admin-varnz.webgarh.net/images/tool/Pixel.png'
        },
        {
            value: 'art',
            label: 'Art',
            image: 'https://admin-varnz.webgarh.net/images/tool/Art.png'
        },
        {
            value: 'authentic',
            label: 'Authentic',
            image: 'https://admin-varnz.webgarh.net/images/tool/Authentic.png'
        }
    ];


    const transformOptions = (items) =>
        items.map(item => ({
            value: item.value,
            label: item.label,
            image: item.image,
        }));

    const getGenderOptions = () => {
        const allSelected = [...formData.selectedGender, ...selectGenders];
        // console.log(...formData.selectedGender, 'aall');

        const uniqueOptions = Array.from(new Set(allSelected.map(option => option.value)))
            .map(value => allSelected.find(option => option.value === value));
        return transformOptions(uniqueOptions);
    };

    const getCreateForOptions = () => {
        const allSelected = [...formData.createFor, ...createFors];
        const uniqueOptions = Array.from(new Set(allSelected.map(option => option.value)))
            .map(value => allSelected.find(option => option.value === value));
        return transformOptions(uniqueOptions);
    };

    const getCreateStylesOptions = () => {
        const allSelected = [...formData.createStyle, ...createStyles];
        const uniqueOptions = Array.from(new Set(allSelected.map(option => option.value)))
            .map(value => allSelected.find(option => option.value === value));
        return transformOptions(uniqueOptions);
    };

    const createStylesOptions = getCreateStylesOptions();
    const selectCreateFors = getCreateForOptions();
    const selectGenderOptions = getGenderOptions();


    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        console.log("Tab changed to:", newValue);
    };

    const handleInputChange = (e) => {
        console.log(e, 'e');

        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleLibraryChange = (e) => {
        const newLibrary = e.target.value;
        setLibrary(newLibrary);
        setModel("");
        setFormData(prevState => ({
            ...prevState,
            library: newLibrary,
            model: ""
        }));
    };

    const handleModelChange = (e) => {
        const selectedModel = e.target.value;
        setModel(selectedModel);
        setFormData(prevState => ({
            ...prevState,
            model: selectedModel
        }));
    };




    const cookie = new Cookies();
    const [selectOccasionResponse, setSelectOccasionResponse] = useState([]);
    const fetchSelectOccasion = async () => {
        try {
            const token = cookie.get("authToken");
            const response = await axios.get(process.env.REACT_APP_SELECT_OCCASION, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                params: { type: "only_active" }
            });
            if (response.data.status === 1) {
                setSelectOccasionResponse(response.data.data);
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const { toolId } = useParams();
    const fetchToolsData = async () => {
        try {
            const token = cookie.get('authToken');
            const url = `${process.env.REACT_APP_SELECT_IMAGINATION}/${toolId}`;

            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
            });

            if (response.data.status === 1) {
                const data = response.data.data[0];
                // console.log(data, 'data');

                const newFormData = {
                    label: data.label || "",
                    key: data.key || "",
                    // value: data.value || "",
                    limit: data.limit || "",
                    prompt: data.prompt || "",
                    isActive: data.isActive || false,
                    position: data.position || 0,
                    library: data.library || "",
                    model: data.model || "",
                    selectedGender: data.selectedGender || [],
                    createFor: data.createFor || [],
                    createStyle: data.createStyle || [],
                    selectOccasionList: data.selectOccasionList || []
                };
                console.log(newFormData.selectedGender, 'aaa');

                setFormData(newFormData);
                setLibrary(data.library || "");
                setModel(data.model || "");
            }
        } catch (err) {
            console.error("Error fetching tool data:", err.message);
        }
    };


    const [filteredOccasionResponse, setFilteredOccasionResponse] = useState([]);

    const updateFilteredOccasions = () => {
        const filteredOptions = selectOccasionResponse.filter(option =>
            !formData.selectOccasionList.includes(option.value)
        );
        setFilteredOccasionResponse(filteredOptions);
    };


    useEffect(() => {
        updateFilteredOccasions();
    }, [selectOccasionResponse, formData.selectOccasionList]);


    useEffect(() => {
        if (toolId) {
            fetchToolsData();
        }
        console.log(toolId, 'toolId');
    }, [toolId]);




    useEffect(() => {
        fetchSelectOccasion();
    }, []);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formObject = { ...formData };
        console.log(formObject, 'formObject');

        const token = cookie.get("authToken");

        try {
            const response = await axios.put(`${process.env.REACT_APP_SELECT_IMAGINATION}/${toolId}`,
                formObject, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
            });

            if (response.data.status === 1) {
                toast.success("Successfully updated!");
                // setTimeout(() => {
                //     navigate('/tools-list');
                // }, 3000);
            } else {
                toast.error(response.data.message || "Update failed!");
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message || "An error occurred.");
            } else {
                toast.error("An error occurred while updating the record.");
            }
        }
    };

    const handleThumbnail = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result; // Base64 string
                setFormData((oldData) => ({
                    ...oldData,
                    image: base64String, // For backend
                    imagePreview: URL.createObjectURL(file) // For preview
                }));
            };

            reader.readAsDataURL(file);
        } else {
            setFormData((oldData) => ({
                ...oldData,
                image: "",
                imagePreview: ""
            }));
        }
    };

    const deleteLogo = () => {
        if (submitBtn.disabled) {
            return;
        }
        setFormData((oldData) => ({ ...oldData, ["image"]: "" }))
    }






    // rewrite tool

    const [reWriteData, setReWriteData] = useState({
        label: "",
        key: "",
        limit: "",
        prompt: "",
        position: "",
        isActive: true
    });
    useEffect(() => {
        const checkToolExists = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_REWRITE}`);
                const toolData = response.data;

                // Check if toolId exists in response data
                const foundTool = toolData.find((tool) => tool._id === toolId);
                console.log(foundTool, 'foundTool');

                if (foundTool) {
                    setToolExists(false); // Tool exists, render all tabs
                    setReWriteData(foundTool);
                } else {
                    setToolExists(true); // Tool doesn't exist, hide last two tabs
                }
            } catch (error) {
                console.error("Error fetching tool data:", error);
            }
        };

        checkToolExists();
    }, [toolId]);


    const rewriteHandleSubmit = async (event) => {
        event.preventDefault();

        const formObject = { ...reWriteData };
        console.log(formObject, 'formObject');

        const token = cookie.get("authToken");

        try {
            const response = await axios.put(`${process.env.REACT_APP_REWRITE}/${toolId}`,
                formObject, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
            });

            if (response.data.status === 1) {
                toast.success("Successfully updated!");
            } else {
                toast.error(response.data.message || "Update failed!");
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data.message || "An error occurred.");
            } else {
                toast.error("An error occurred while updating the record.");
            }
        }
    };
    const WriteHandleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value, 'input change');

        setReWriteData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const rewriteHandleThumbnail = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result; // Base64 string
                setReWriteData((oldData) => ({
                    ...oldData,
                    image: base64String, // For backend
                    imagePreview: URL.createObjectURL(file) // For preview
                }));
            };

            reader.readAsDataURL(file);
        } else {
            setReWriteData((oldData) => ({
                ...oldData,
                image: "",
                imagePreview: ""
            }));
        }
    };

    const rewriteDeleteLogo = () => {
        if (submitBtn.disabled) {
            return;
        }
        setReWriteData((oldData) => ({ ...oldData, ["image"]: "" }))
    }
    // Handle library change
    const rewriteHandleLibraryChange = (e) => {
        const newLibrary = e.target.value;

        // Clear model when a new library is selected
        setLibrary(newLibrary);
        setModel("");

        setReWriteData(prevState => ({
            ...prevState,
            library: newLibrary,  // Update library
            model: ""  // Clear model selection when library changes
        }));
    };

    // Handle model change
    const rewriteHandleModelChange = (e) => {
        const selectedModel = e.target.value;

        setModel(selectedModel);  // Update model state

        setReWriteData(prevState => ({
            ...prevState,
            model: selectedModel  // Update selected model
        }));
    };

    return (
        <div>
            <ToastContainer />
            <div className="admin-topbar">
                <h5 className="Main-heading">Update Tool</h5>
            </div>
            <Row>
                <Col sm="12" lg="9" xl="9" className="blog-right-side">
                    <div className="card card-body blog-details">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <TabList onChange={handleTabChange}>
                                        <Tab label="General" value="1" />
                                        {toolExists && <Tab label="Choose Occasion" value="2" />}
                                        {toolExists && <Tab label="Choose Gender" value="3" />}
                                        {toolExists && <Tab label="Others" value="4" />}
                                    </TabList>
                                </Box>
                                {toolExists ? (
                                    <>
                                        <form onSubmit={handleSubmit}>
                                            <div className="tab-content">
                                                <TabPanel value="1">
                                                    <div className="form-group">
                                                        <label className="card-title">Tool Name <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: AI Imagine)"
                                                            name="label"
                                                            value={formData.label}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                            
                                                    <div className="form-group">
                                                        <h5 className="card-title">
                                                            Image <span style={{ color: "red" }}>*</span>
                                                        </h5>
                                                        <div className="selct-profile-picture">
                                                            <div className="company-logo-selection-img">
                                                                {
                                                                    formData.image ? (
                                                                        <img
                                                                            src={formData.image} // Base64 image from formData
                                                                            width="100%"
                                                                            alt="Image Preview"
                                                                        />
                                                                    ) : (
                                                                        <img src={bgProfile} width="100%" alt="Default Image" />
                                                                    )
                                                                }
                                                            </div>
                                                            {
                                                                formData.image ? (
                                                                    <div
                                                                        className="company-logo-selection-file"
                                                                        onClick={rewriteDeleteLogo}
                                                                    >
                                                                        <label htmlFor="choose-logo" className={`cstm-btn delete-logo`}>
                                                                            <i className="far fa-image"></i> Delete Photo
                                                                        </label>
                                                                    </div>
                                                                ) : (
                                                                    <div className="company-logo-selection-file">
                                                                        <input
                                                                            id="choose-logo"
                                                                            className="cstm-form company"
                                                                            type={"file"}
                                                                            accept="image/*"
                                                                            name="image"
                                                                            onChange={rewriteHandleThumbnail}
                                                                            disabled={submitBtn.disabled}
                                                                        />
                                                                        <label htmlFor="choose-logo" className="cstm-btn">
                                                                            <i className="far fa-image"></i> Upload Photo
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Key <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: ai_imagine)"
                                                            name="key"
                                                            value={formData.key}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    {/* <div className="form-group">
                                                <label  className="card-title">Value <span>*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="(ex: ai imagine)"
                                                    name="value"
                                                    value={formData.value}
                                                    onChange={handleInputChange}
                                                />
                                            </div> */}

                                                    <div className="form-group">
                                                        <label className="card-title">Limit <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: ai imagine)"
                                                            name="limit"
                                                            value={formData.limit}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Prompt <span>*</span></label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Please create image for [Choose Occasion] for gender [Choose Gender] as we are creating image for [Creating For] with style [Choose Style] [IF Special Instruction !=''] and do take care of [Special Instruction]
"
                                                            name="prompt"
                                                            value={formData.prompt}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Choose Library <span>*</span></label>
                                                        <Form.Select
                                                            value={library}
                                                            onChange={handleLibraryChange}
                                                            name="library"
                                                        >
                                                            <option value="" disabled>Select a library</option>
                                                            <option value="open_ai">OpenAI</option>
                                                            <option value="meta_llam3">Meta LLaMA3</option>
                                                        </Form.Select>
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="card-title">Choose Model <span>*</span></label>
                                                        <Form.Select
                                                            value={model}
                                                            onChange={handleModelChange}
                                                            aria-label="Choose Model"
                                                            name="model"
                                                            disabled={!library}
                                                        >
                                                            <option value="" disabled>Select a model</option>
                                                            {library === "open_ai" ? (
                                                                <>
                                                                    <option value="gpt-4-turbo">GPT-4 Turbo - High-intelligence model</option>
                                                                    <option value="gpt-3.5-turbo">GPT-3.5 Turbo - Fast, inexpensive for simple tasks</option>
                                                                    <option value="gpt-4o">GPT-4o - High-intelligence for complex tasks</option>
                                                                    <option value="gpt-4o-mini">GPT-4o mini - Small model for fast tasks</option>
                                                                    <option value="gpt-4">GPT-4 - Previous high-intelligence model</option>
                                                                </>
                                                            ) : library === "meta_llam3" ? (
                                                                <>
                                                                    <option value="34-bit">LLaMA3 - 34-bit</option>
                                                                    <option value="64-bit">LLaMA3 - 64-bit</option>
                                                                </>
                                                            ) : (
                                                                <option value="" disabled>Select a model</option>
                                                            )}
                                                        </Form.Select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Status <span>*</span></label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            value={formData.isActive}
                                                            onChange={handleInputChange}
                                                            name="isActive"
                                                        >
                                                            <option value={true}>Published</option>
                                                            <option value={false}>Draft</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="card-title">Position <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="(ex: 1)"
                                                            name="position"
                                                            value={formData.position}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                </TabPanel>
                                                <TabPanel value="2">
                                                    <label className="card-title">Select Occasion <span>*</span></label>
                                                    <DragDropSelect
                                                        options={selectOccasionResponse}
                                                        value={formData.selectOccasionList}
                                                        onChange={(e) => {
                                                            setFormData((oldData) => ({
                                                                ...oldData,
                                                                selectOccasionList: e
                                                            }));
                                                        }}
                                                    />
                                                </TabPanel>




                                                <TabPanel value="3">
                                                    <label className="card-title">Select Gender <span>*</span></label>
                                                    <DragDropSelect
                                                        options={selectGenderOptions}
                                                        value={formData.selectedGender}
                                                        onChange={(selected) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                selectedGender: selected
                                                            }));
                                                        }}
                                                    />
                                                </TabPanel>
                                                <TabPanel value="4">
                                                    <label className="card-title">Create For <span>*</span></label>
                                                    <DragDropSelect
                                                        options={selectCreateFors}
                                                        value={transformOptions(formData.createFor)}
                                                        onChange={(selected) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                createFor: selected
                                                            }));
                                                        }}
                                                    />
                                                    <label className="card-title">Create Style <span>*</span></label>
                                                    <DragDropSelect
                                                        options={createStylesOptions}
                                                        value={transformOptions(formData.createStyle)}
                                                        onChange={(selected) => {
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                createStyle: selected
                                                            }));
                                                        }}
                                                    />
                                                </TabPanel>
                                                <div className="submit-buttons">
                                                    <button className="save-btn" type="submit">{submitBtn.text}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                ) : (
                                    // for rewrite form
                                    <>
                                        <form onSubmit={rewriteHandleSubmit}>
                                            <div className="tab-content">
                                                <TabPanel value="1">
                                                    <div className="form-group">
                                                        <label className="card-title">Tool Name <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: AI Imagine)"
                                                            name="label"
                                                            value={reWriteData.label}
                                                            onChange={WriteHandleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <h5 className="card-title">
                                                            Image <span style={{ color: "red" }}>*</span>
                                                        </h5>
                                                        <div className="selct-profile-picture">
                                                            <div className="company-logo-selection-img">
                                                                {
                                                                    reWriteData.image ? (
                                                                        <img
                                                                            src={reWriteData.image} // Base64 image from formData
                                                                            width="100%"
                                                                            alt="Image Preview"
                                                                        />
                                                                    ) : (
                                                                        <img src={bgProfile} width="100%" alt="Default Image" />
                                                                    )
                                                                }
                                                            </div>
                                                            {
                                                                reWriteData.image ? (
                                                                    <div
                                                                        className="company-logo-selection-file"
                                                                        onClick={rewriteDeleteLogo}
                                                                    >
                                                                        <label htmlFor="choose-logo" className={`cstm-btn delete-logo`}>
                                                                            <i className="far fa-image"></i> Delete Photo
                                                                        </label>
                                                                    </div>
                                                                ) : (
                                                                    <div className="company-logo-selection-file">
                                                                        <input
                                                                            id="choose-logo"
                                                                            className="cstm-form company"
                                                                            type={"file"}
                                                                            accept="image/*"
                                                                            name="image"
                                                                            onChange={rewriteHandleThumbnail}
                                                                            disabled={submitBtn.disabled}
                                                                        />
                                                                        <label htmlFor="choose-logo" className="cstm-btn">
                                                                            <i className="far fa-image"></i> Upload Photo
                                                                        </label>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="card-title">Key <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: ai_imagine)"
                                                            name="key"
                                                            value={reWriteData.key || ''}
                                                            onChange={WriteHandleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Limit <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="(ex: ai imagine)"
                                                            name="limit"
                                                            value={reWriteData.limit}
                                                            onChange={WriteHandleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Prompt <span>*</span></label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Please create image for [Choose Occasion] for gender [Choose Gender] as we are creating image for [Creating For] with style [Choose Style] [IF Special Instruction !=''] and do take care of [Special Instruction]
"
                                                            name="prompt"
                                                            value={reWriteData.prompt}
                                                            onChange={WriteHandleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Choose Library <span>*</span></label>
                                                        <Form.Select
                                                            value={reWriteData.library}
                                                            onChange={rewriteHandleLibraryChange}
                                                            name="library"
                                                        >
                                                            <option value="" disabled>Select a library</option>
                                                            <option value="open_ai">OpenAI</option>
                                                            <option value="meta_llam3">Meta LLaMA3</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Choose Model <span>*</span></label>
                                                        <Form.Select
                                                            value={reWriteData.model}
                                                            onChange={rewriteHandleModelChange}
                                                            aria-label="Choose Model"
                                                            name="model"
                                                            disabled={!reWriteData.library}  // Disable model selection if no library is chosen
                                                        >
                                                            <option value="" disabled>Select a model</option>

                                                            {reWriteData.library === "open_ai" ? (
                                                                <>
                                                                    <option value="gpt-4-turbo">GPT-4 Turbo - High-intelligence model</option>
                                                                    <option value="gpt-3.5-turbo">GPT-3.5 Turbo - Fast, inexpensive for simple tasks</option>
                                                                    <option value="gpt-4o">GPT-4o - High-intelligence for complex tasks</option>
                                                                    <option value="gpt-4o-mini">GPT-4o mini - Small model for fast tasks</option>
                                                                    <option value="gpt-4">GPT-4 - Previous high-intelligence model</option>
                                                                </>
                                                            ) : reWriteData.library === "meta_llam3" ? (
                                                                <>
                                                                    <option value="34-bit">LLaMA3 - 34-bit</option>
                                                                    <option value="64-bit">LLaMA3 - 64-bit</option>
                                                                </>
                                                            ) : (
                                                                <option value="" disabled>Select a model</option>
                                                            )}
                                                        </Form.Select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="card-title">Status <span>*</span></label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            value={reWriteData.isActive}
                                                            onChange={WriteHandleInputChange}
                                                            name="isActive"
                                                        >
                                                            <option value={true}>Published</option>
                                                            <option value={false}>Draft</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="card-title">Position <span>*</span></label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            placeholder="(ex: 1)"
                                                            name="position"
                                                            value={reWriteData.position}
                                                            onChange={WriteHandleInputChange}
                                                        />
                                                    </div>

                                                </TabPanel>

                                                <div className="submit-buttons">
                                                    <button className="save-btn" type="submit">{submitBtn.text}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                )}
                            </TabContext>
                        </Box>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default UpdateTool;
