// import { useRoutes } from "react-router-dom";
// import Themeroutes from "./routes/Router";

// import "./app.css";
// const App = () => {
//   const routing = useRoutes(Themeroutes);

//   return <div className="dark">{routing}</div>;
// };

// export default App;


import { BrowserRouter as Router, Routes, Route,useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import "./app.css";
import { lazy } from 'react';
import ListSelectOccasion from './views/ui/listSelectOccasion.js';
import Tool from './views/ui/tools/tool.js';
import ListTools from './views/ui/listTools.js';
import UpdateTool from './views/ui/updateListtools.js';

/****Layouts*****/
const FullLayout = lazy(() => import('./layouts/FullLayout.js'));

/***** Pages ****/
const Starter = lazy(() => import('./views/Starter.js'));
const About = lazy(() => import('./views/About.js'));
const Alerts = lazy(() => import('./views/ui/Alerts'));
const Badges = lazy(() => import('./views/ui/Badges'));
const Buttons = lazy(() => import('./views/ui/Buttons'));
const Cards = lazy(() => import('./views/ui/Cards'));
const CreateBlog = lazy(() => import("./views/ui/createBlog.js"));
const CreateAiPage = lazy(() => import("./views/ui/createAiPage.js"));
const CreateContent = lazy(() => import("./views/ui/createContent.js"));

const UpdateBlog = lazy(() => import("./views/ui/updateBlog.js"));
const UpdateWebContent = lazy(() => import("./views/ui/updateWebcontent.js"));

const Category = lazy(() => import("./views/ui/category.js"));
const UpdateCategory = lazy(() => import("./views/ui/updateCategory.js"));
const CategoryList = lazy(() => import("./views/ui/categoryList.js"));
const BlogList = lazy(() => import("./views/ui/blogList.js"));
const WebContentList = lazy(() => import("./views/ui/listContent.js"));
const ListAiPage = lazy(() => import("./views/ui/listAIPage.js"));

const Grid = lazy(() => import('./views/ui/Grid'));
const Tables = lazy(() => import('./views/ui/Tables'));
const Forms = lazy(() => import('./views/ui/Forms'));
const Breadcrumbs = lazy(() => import('./views/ui/Breadcrumbs'));
const Login = lazy(() => import('./components/Login/Login.jsx'));
const AuthGuard = lazy(() => import('./guard/AuthGuard.jsx'));
const ForgotPassword = lazy(() => import('./views/ui/forgot.js'));
const PasswordOtp = lazy(() => import('./views/ui/OtpForgotPassword.js'));
const CreatePassword = lazy(() => import('./views/ui/Createpassword.js'));
const PasswordUpdated = lazy(() => import('./views/ui/Passwordupdate.js'));
const ChnagePassword = lazy(() => import('./views/ui/changePassword.js'));
const UpdateAIPage = lazy(() => import('./views/ui/updateAiPage.js'));
const UpdateAIPageCopy = lazy(() => import('./views/ui/updateAiPage copy.js'));

const ListMainPage = lazy(() => import('./views/ui/listMainPage.js'));
const CreateMainPage = lazy(() => import('./views/ui/createMainPage.js'));
const UpdateMainPage = lazy(() => import('./views/ui/updateMainPage.js'));

const NewsLetter = lazy(() => import('./views/ui/newsletterList.js'));
const NewsLetterDetail = lazy(() => import('./views/ui/newsletterListSentBlog.js'));

const CreateAiCategory = lazy(() => import('./views/ui/createAiCategory.js'));
const ListAiPageCategory = lazy(() => import('./views/ui/aiCategoryList.js'));
const UpdateAiPageCategory = lazy(() => import('./views/ui/updateAIPageCategory.js'));
const CreateSitemap = lazy(() => import('./views/ui/createSitemap.js'));
const Htaccess = lazy(() => import('./views/ui/htaccess.js'));
const Categories = lazy(() => import('./views/ui/categories.js'));
const Pages = lazy(() => import('./views/ui/pages.js'));

const BannerTheme = lazy(() => import('./views/ui/bannerTheme.js'));
const CreateBannerTheme = lazy(() => import('./views/ui/createBannerTheme.js'));
const ListBannerTheme = lazy(() => import('./views/ui/listBannerTheme.js'));
const UpdateBannerTheme = lazy(() => import('./views/ui/updateBannerTheme.js'));

// form category
const CreateFormCategory = lazy(() => import('./views/ui/formCategory.js'));
const CreateFormType = lazy(() => import('./views/ui/formType.js'));
const Test = lazy(() => import('./views/ui/test.js'));

const SelectType = lazy(() => import('./views/ui/selectType.js'));
const ListSelectType = lazy(() => import('./views/ui/listSelectType.js'));
const UpdateSelectType = lazy(() => import('./views/ui/updateSelectType.js'));
const CreateSelectOccasion = lazy(() => import('./views/ui/createSelectOccasion.js'));
const ListsSelectOccasion = lazy(() => import('./views/ui/listSelectOccasion.js'));
const UpdateSelectOccasion = lazy(() => import('./views/ui/updateSelectOccasion.js'));
const ListVisitedUser = lazy(() => import('./views/ui/visitedUserList.js'));

const CreateSelectCategory = lazy(() => import('./views/ui/createSelectCategory.js'));
const ListSelectCategory = lazy(() => import('./views/ui/listSelectCategory.js'));
const UpdateSelectCategory = lazy(() => import('./views/ui/updateSelectCategory.js'));

const RegisterUsers = lazy(() => import('./views/ui/registerUsers.js'));




const App = () => {
  const navigate = useNavigate();
  return <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/password-otp" element={<PasswordOtp />} />
    <Route path="/create-password" element={<CreatePassword />} />
    <Route path="/update-password" element={<PasswordUpdated />} />

    <Route element={<AuthGuard />}>
      <Route path="/" element={<FullLayout />}>
        {/* <Route path="/" element={<navigate to="/starter" />} /> */}
        <Route path="starter" element={<Starter />} />
        <Route index element={<Starter />} />
        <Route path="create-post" element={<CreateBlog />} />
        <Route path="create-ai-page" element={<CreateAiPage />} />
        <Route path="create-content" element={<CreateContent />} />
        <Route path="update-post/:blogId" element={<UpdateBlog />} />
        <Route path="update-web-content/:blogId" element={<UpdateWebContent />} />
        <Route path="update-ai-page/:blogId" element={<UpdateAIPage />} />
        <Route path="create-category" element={<Category />} />
        <Route path="update-category/:categoryId" element={<UpdateCategory />} />
        <Route path="category" element={<CategoryList />} />
        <Route path="posts" element={<BlogList />} />
        <Route path="change-password" element={<ChnagePassword />} />
        <Route path="list-post" element={<BlogList />} />
        <Route path="list-content" element={<WebContentList />} />
        <Route path="list-ai-page" element={<ListAiPage />} />
        <Route path="create-main-page" element={<CreateMainPage />} />
        <Route path="list-main-page" element={<ListMainPage />} />
        <Route path="update-main-page/:blogId" element={<UpdateMainPage />} />
        <Route path="detail-newsletter/:blogId" element={<NewsLetterDetail />} />
        <Route path="list-newsletter" element={<NewsLetter />} />
        <Route path="ai-page-category" element={<ListAiPageCategory />} />
        <Route path="create-ai-page-category" element={<CreateAiCategory />} />
        <Route path="update-ai-page-category/:categoryId" element={<UpdateAiPageCategory />} />
        <Route path="create-sitemap" element={<CreateSitemap />} />
        <Route path="redirection" element={<Htaccess />} />
        <Route path="categories" element={<Categories />} />
        <Route path="pages" element={<Pages />} />
        <Route path="create-banner-theme" element={<CreateBannerTheme />} />
        <Route path="list-banner-theme" element={<ListBannerTheme />} />
        <Route path="update-banner-theme/:blogId" element={<UpdateBannerTheme />} />
        <Route path="form-category" element={<CreateFormCategory />} />
        <Route path="form-type" element={<CreateFormType />} />
        <Route path="test" element={<Test />} />
        <Route path="add-select-type" element={<SelectType />} />
        <Route path="list-select-type" element={<ListSelectType />} />
        <Route path="update-select-type/:selectTypeId" element={<UpdateSelectType />} />
        <Route path="create-select-occasion" element={<CreateSelectOccasion />} />
        <Route path="list-select-occasion" element={<ListSelectOccasion />} />
        <Route path="update-select-occasion/:selectTypeId" element={<UpdateSelectOccasion />} />
        <Route path="list-visited-user" element={<ListVisitedUser />} />
        <Route path="create-select-category" element={<CreateSelectCategory />} />
        <Route path="list-select-category" element={<ListSelectCategory />} />
        <Route path="update-select-category/:selectTypeId" element={<UpdateSelectCategory />} />
        <Route path="register-users" element={<RegisterUsers />} />

        <Route path="tools" element={<Tool />} />
        <Route path="tools-list" element={<ListTools />} />
        <Route path="update-select-tool/:toolId" element={<UpdateTool />} />


      </Route>
    </Route>

  </Routes>
};

export default App;
